<template>
    <b-modal id="files-modal"
                ref="fileManagement"
                
                size="md"
                centered
                modal-class="modal-table modal-files"
                :no-close-on-backdrop="true"
                :no-close-on-esc="true"
    >
        <template #modal-header="{}">
            <div class="title">{{ getFmText('title') }}</div>
        </template>

        <template #modal-footer="{}">
            <b-button size="md" @click="onModalClose">
                {{ getFmText('cancel') }}
            </b-button>
            <b-button size="md"  :disabled="!dataChanged" variant="success" @click="saveRowFiles">
                {{ getFmText('apply') }}
            </b-button>
            <b-button size="md" @click="downloadAttachedFiles" variant="outline-success">
                {{ getFmText('download') }}
            </b-button>
        </template>

        <div class="modal-search">
            <b-form-group>
                <b-form-select v-model="selectedCategory" :options="categoryFiles" text-field="name"></b-form-select>
            </b-form-group>
            <b-form-group>
                <b-form-input ref="searchTextField" v-model="searchText" :placeholder="getFmText('search')" type="text"/>
                <i class="icon icon-close clear-button" @click="() => { searchText = '' }"/>
            </b-form-group>  
        </div>
        
        <div class="table-container modal-table-card mb-3">
            <p class="file-cat-name">{{ getFmText('attached') }}:</p>
            <p class="file-not-found" v-show="!searchedAttachedFiles.length">{{ getFmText('not_found') }}</p>

            <div class="file-info" v-for="item in this.searchedAttachedFiles" :key="item.file_id">
                <div class="d-flex align-items-center">
                    <b-form-checkbox
                        v-model="item.selected"
                        name="checkbox-1"
                        :disabled="!variantAttribute"
                        @change="onFilesListChanged(item, false)"
                    >
                    </b-form-checkbox>
                    <p class="name">
                        <file-icons :filename="item.file_name"></file-icons>
                        <span style="cursor: pointer" @click="()=>$emit('triggDownloadFile', item.file_name)">{{item.file_name}}</span>
                    </p>
                    <p class="size">{{item['size']}}</p>
                    <p class="size">({{getCatName(item.file_type)}})</p>
                </div>
            </div>
        </div>
        <hr>
        <div class="table-container modal-table-card mb-3">
            <p class="file-cat-name">{{ getFmText('other') }}:</p>
            <p class="file-not-found" v-show="!searchedAllFiles.length">{{ getFmText('not_found') }}</p>

            <div class="file-info" v-for="item in this.searchedAllFiles" :key="item.file_id">
                <div class="d-flex align-items-center">
                    <b-form-checkbox
                        v-model="item.selected"
                        name="checkbox-1"
                        
                        :disabled="!variantAttribute"
                        @change="onFilesListChanged(item, true)"
                    >
                    </b-form-checkbox>
                    <p class="name">
                        <file-icons :filename="item.file_name"></file-icons>
                        <span style="cursor: pointer" @click="()=>$emit('triggDownloadFile', item.file_name)">{{item['file_name']}}</span>
                    </p>
                    <p class="size">{{item['size']}}</p>
                    <p class="size">({{getCatName(item.file_type)}})</p>
                </div>
            </div>
        </div>
        <b-button
                size="sm"
                variant="primary"
                @click="fileUpload"
                :disabled="!variantAttribute"
            >
                {{ getFmText('upload') }}
        </b-button>

    </b-modal>
</template>

<script>
import FileUpdownMixin from '../mixins/file-updown-mixin';
import FileIcons from './file-icons.vue';

export default {
    name: "modal-files-management-nodecode",
    components: { FileIcons },
    props: {
        variantAttribute: Boolean,
    },

    mixins: [FileUpdownMixin],
    data() {
        return {
            selectedCategory: 'All',
            searchText: '',
            currItem: null,
            newFileCodes: null,
            dataChanged: false,
            newFileAdded: false,

            allFiles: [],
        }
    },
    watch: {
        currItem: {
            handler: function() {
            this.getAllFiles();
            },
            deep: true // Добавляем опцию deep: true для глубокой реактивности
        }
    },
    methods: {
        async showModal(item) {           
            this.currItem = item;
            await this.loadDict();
            this.setAllCat();
            this.getAllFiles();
            this.$refs.fileManagement.show();
        },

        getAllFiles() {
            let newArr = [];
            if (this.currItem && this.currItem.row_files && this.currItem.row_files.length) {
                newArr = this.currItem.row_files.map(item => {
                    item.new_file = false;
                    return JSON.parse(JSON.stringify(item));
                });
            };
            this.allFiles = newArr;
        },

        getCatName(code) {
            const currCat = this.categoryFiles.find(item => item.value === code);
            return currCat ? currCat.name : null
        },

        onFilesListChanged(item, attachFile) {
            if (!this.currItem) return;
            const currFile = this.allFiles.find(itm => itm.file_id === item.file_id)
            if (currFile) {
                if (attachFile) {
                    this.$set(currFile, 'row_id', this.currItem.id);
                } else {
                    this.$set(currFile, 'row_id', null);
                };
            }
            this.dataChanged = true;
        },
        
        addNewFiles(files) {
            files.forEach(file => {
                this.allFiles.push({...file, new_file: true})
                this.onFilesListChanged(file, true)
            })
        },

        onModalClose(e) {
            if (this.dataChanged) {
                e.preventDefault();
                this.onFilesModalClose();
            } else {
                this.restetModal();
            }
        }, // отчистка 'goods-modal' при закрытии без сохранения

        restetModal(){
            this.currItem = null;
            this.dataChanged = false;
            this.$bvModal.hide('files-modal');
        },

        onFilesModalClose() {
            this.$bvModal.msgBoxConfirm(
                this.getFmText('on_close'),
                {   
                    title: this.getFmText('attention'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getFmText('continue'),
                    cancelTitle: this.getFmText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        this.addingNewFiles(this.currItem, this.allFiles);
                        this.restetModal(true);
                    } 
                })
                .catch(error => {
                    this.makeToast('danger', this.getFmText('error'), error.toString());
                });
        },

        fileUpload() {
            this.$emit('fileUpload', this.currItem.id);
        }, // Запуск модалки отвечающей за загрузку файлов

        async saveRowFiles() {
            let num_attach_files = 0;
            const newRowFiles = [];
            this.allFiles.forEach(file => {
                if (file['row_id'] !== null) {
                    num_attach_files += 1
                }
                newRowFiles.push(JSON.parse(JSON.stringify(file)))
            });
            const return_object = {
                isAdd: false,
                row_files: newRowFiles,
                num_attach_files: num_attach_files
            };
            this.$emit('toggleIsAdd', return_object);
            this.resetModalAfterSave();
        },

        resetModalAfterSave(isCancel = false) {
            this.$bvModal.hide('files-modal')
            this.allFiles.forEach(itm => {
                this.$set(itm, 'row_id', null);
                this.$set(itm, 'selected', false);
            });
            this.currItem = null;
            this.dataChanged = false;
        },

        addingNewFiles(currItem, allFiles) {
            allFiles.forEach(file => {
                if (file.new_file === true) {
                    currItem.row_files.push({...file, row_id: null, selected: false});       
                }
            });
        },

        setDataChanged(val) {
            this.dataChanged = val;
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        downloadAttachedFiles() {
            if (this.allFiles.length) {
                const fileNamesList = [];
                this.allFiles.forEach(itm => {
                    if (itm.row_id) fileNamesList.push(itm.file_name);
                })

                if (fileNamesList.length) this.$emit('triggDownloadAllFiles', fileNamesList);
                else this.makeToast('warning', this.getFmText('warning'), this.getFmText('no_files'));
            } else this.makeToast('warning', this.getFmText('warning'), this.getFmText('no_files'));

        },

        getFmText(field, extra = null) {
            const link = 'modules.budget_request.files_management.';
            return this.getText(link, field, extra)
        },
    },
    computed: {
        searchedAllFiles() {
            if (!this.allFiles) return [];
            const sortedFiles = this.allFiles.filter(item => {
                if (item.row_id !== null) return false; // убираем прикрепленные
                let result = true;
                if (this.selectedCategory !== "All") {
                    result = result && item.file_type === this.selectedCategory;
                };
                if (this.searchText.length) {
                    result = result && item.file_name.toLowerCase().includes(this.searchText.toLowerCase());
                };
                // result = result && !item.row_id;
                return result;
            });
            return sortedFiles.sort((a, b) => a.file_name > b.file_name ? 1 : -1);
        },
        searchedAttachedFiles() {
            if (!this.allFiles) return [];
            // let sortedFiles = this.attachedFiles;
            const sortedFiles = this.allFiles.filter(item => {
                if (item.row_id === null) return false; // убираем не прикрепленные
                let result = true;
                if (this.selectedCategory !== "All") {
                    result = result && item.file_type === this.selectedCategory;
                };
                if (this.searchText.length) {
                    result = result && item.file_name.toLowerCase().includes(this.searchText.toLowerCase());
                };
                item.selected = true;
                return result;
            });
            return sortedFiles.sort((a, b) => a.file_name > b.file_name ? 1 : -1);
        },
    },
}

</script>

<style scoped>
.file-cat-name {
    font-size: 0.9rem;
}
.file-not-found {
    font-size: 0.8rem;
    font-style: italic;
    color: #A8B5CD;
    margin-left: 2rem;
}
.serch-group {
    margin-top: 10px;
    display: flex;
}
.serch-group icon {
    position: absolute;
    right: 25px;
    margin-top: 5px;
}
</style>