





























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    name: 'c-set-bf-category-name',
    
})

export default class CSetBFCategoryName extends Vue {
    @Prop({
        required: true,
    })
    private budgetForm!: any;

    @Prop({
        required: true,
    })
    private categoryFieldNames!: any;

    @Prop({
        required: true,
    })
    private lng!: string;

    @Prop({
        required: false,
    })
    private defaultCategoryNames!: any;

    @Prop({
        required: false,
    })
    private getCommonText!: any;

    private isNameChanged = false;

    private name_ru = '';
    private name_kk = '';

    private get categoryFieldName_ru() {
        return this.categoryFieldNames?.ru ? this.categoryFieldNames.ru : '';
    }

    private get categoryFieldName_kk() {
        return this.categoryFieldNames?.kk ? this.categoryFieldNames.kk : '';
    }

    private get okBtnName() {
        return this.isEditMode ? this.getCommonText('save') : this.getCommonText('add');
    }

    private isModalShown = false;

    private isEditMode = false;

    private curRowId = null;
    private curRow: {[key: string]: string} = {};


    private showModal(row?: any) {
        this.setModalParams(row);
        this.isModalShown = true;
    }

    private setModalParams(row?: any) {
        if (row) {
            this.curRowId = row.id;
            this.curRow = row;
        }
        this.isEditMode = !!row;
        if (!this.isEditMode) this.setDefaultCatNames();
        else this.setCatNames(row);
    }

    private setDefaultCatNames() {
        if (this.defaultCategoryNames) {
            if (this.defaultCategoryNames.ru) {
                this.name_ru = this.defaultCategoryNames.ru;
                this.isNameChanged = true;
            }
            if (this.defaultCategoryNames.kk) {
                this.name_kk = this.defaultCategoryNames.kk;
                this.isNameChanged = true;
            }
        }
    }

    private setCatNames(row: any) {
        const field_ru = this.categoryFieldName_ru;
        const field_kk = this.categoryFieldName_kk;
        this.name_ru = field_ru && row[field_ru] ? row[field_ru] : '';
        this.name_kk = field_kk && row[field_kk] ? row[field_kk] : '';
    }

    private hideModal() {
        this.isModalShown = false;
    }

    private resetForm() {
        this.name_ru = '';
        this.name_kk = '';
        this.resetNameChanged();
        this.curRowId = null;
        this.curRow = {};
    }

    private maxNameLengthSymbls = 255;
    private onInput(value: any, code: string) {
        const valueWithoutNewlines = value.replace(/\n/g, '');

        const cuttedValue = valueWithoutNewlines.slice(0, this.maxNameLengthSymbls);

        this.$nextTick(() => {
            if (code === 'ru') {
                this.name_ru = cuttedValue;
            } else {
                this.name_kk = cuttedValue;
            }
        });
    }

    private resetNameChanged() {
        this.isNameChanged = false;
    }

    private onChangeName() {
        const isBothNamesEnpty = !this.name_ru.trim() && !this.name_kk.trim();

        if (isBothNamesEnpty) this.resetNameChanged()
        else this.isNameChanged = true;
    }

    private onOkBtnClicked() {
        if (this.checkIfDouble(this.budgetForm)) {
            this.makeToast('warning', this.getCommonText('attention'), this.getCommonText('exist'));
            return;
        }

        const isFormEmpty = !this.name_ru.trim() && !this.name_kk.trim();
        if (isFormEmpty) return;


        if (this.isEditMode && this.curRow) {
            this.$set(this.curRow, this.categoryFieldName_ru, this.name_ru);
            this.$set(this.curRow, this.categoryFieldName_kk, this.name_kk);
        } else {
            this.$emit('addItem', this.name_ru, this.name_kk);
        }

        this.$emit('onCatNameChanged', this.curRow);
        this.hideModal();
    }

    private checkIfDouble(data: any) {
        if (!data || !data.length) return false;

        const field_ru = this.categoryFieldName_ru;
        const field_kk = this.categoryFieldName_kk;
        for (const itm of data) {
            const isEditingRow = this.isEditMode && this.curRowId && itm.id === this.curRowId;
            if (!isEditingRow) {
                const trimmedName1_ru = itm[field_ru] ? itm[field_ru].trim().toLowerCase() : '';
                const trimmedName1_kk = itm[field_kk] ? itm[field_kk].trim().toLowerCase() : '';
                const trimmedName2_ru = this.name_ru ? this.name_ru.trim().toLowerCase() : '';
                const trimmedName2_kk = this.name_kk ? this.name_kk.trim().toLowerCase() : '';
                const isDouble = (trimmedName2_ru && trimmedName1_ru === trimmedName2_ru) 
                                || (trimmedName2_kk && trimmedName1_kk === trimmedName2_kk);
                const isCategorySame = this.curRow && this.curRow.category_id && itm.category_id && this.curRow.category_id === itm.category_id;
                if (isDouble && !isCategorySame) return true;
            }                
        }
        return false;
    }

    private makeToast(variant: string, title: string, tostbody: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}

