<template>
        <div class="actions-tab actions-tab-budget-forms">
             <!-- скачивание инструкций -->
            <budget-attach-file/>
            
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret :disabled="isReportUploading || progress != 100" ref="dropdown" @shown="adjustDropdownMenuStyle">
                <template #button-content>
                    <div class="btn-download">
                        <div>
                    <span class="left">
                        <b-spinner v-if="isReportUploading" variant="success" small label="Small Spinner"></b-spinner>
                        <i v-if="!isReportUploading" class="icon icon-download"></i> {{ buttonsText.download }}
                    </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-item @click="onClick(false)"> {{ formLocalText }}</b-dropdown-item>
                <b-dropdown-item v-if="isGkkpMode && isGkkpPaid" @click="onClickPaid(false)"> {{ formLocalPaid }}</b-dropdown-item>
                <b-dropdown-item @click="onClick(true, true)" :disabled="$store.state.isDownloadButtonEnabled">
                    {{ batchReportLocalText }}
                </b-dropdown-item>
                <b-dropdown-item v-if="isGkkpMode" @click="onClickPaid(true, true)" :disabled="$store.state.isDownloadButtonEnabled">
                    {{ batchReportGkkpPaid }}
                </b-dropdown-item>
                <b-dropdown-item 
                    @click="onClick(true, false)" 
                    :disabled="$store.state.isDownloadButtonEnabled" 
                    v-if="isABPReportAvailable">
                    {{ batchReportAbpLocalText }}
                </b-dropdown-item>
                <b-dropdown-item 
                    v-if="isParentGuExist" 
                    @click="onClick(true, false, 'edu')" 
                    :disabled="$store.state.isDownloadButtonEnabled"> 
                        {{ batchReportEducationDepText }}
                </b-dropdown-item>
            </b-dropdown>
        </div>
</template>

<script>
import BudgetAttachFile from "./budget-attach-file";

export default {
    name: 'FormsSaveBtn',
    components: { BudgetAttachFile, },
    props: {
        progress: {
            type: Number,
            required: true,
            defaut: 0
        },
        isReportUploading: {
            type: Boolean,
            required: true,
            defaut: false
        },
        form: {
            type: Object,
            required: true,
            defaut: { 
                code: ''
                }
        },
        guListLen: {
            type: Number,
            defaut: 0
        },
        header: {
            type: Object,
            required: false,
        },

        isParentGuExist: {
            type: Boolean,
            required: false,
            defaut: false
        },

        isHeadGu: {
            type: Boolean,
            required: false,
            defaut: false
        },
        isGkkpPaid: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    methods: {
        onClick(batch = false, oneGu = false, type = null) {
            this.$emit('checkSignatories', batch, oneGu);
            if (batch) {
                this.$emit('downloadBatchReports', oneGu, type);
            } else {
                this.$emit('downloadRep');
            }
        },

        onClickPaid(batch = false, oneGu = false) {
            this.$emit('checkSignatories', batch, oneGu);
            if (batch) {
                this.$emit('downloadBatchReportsPaid', oneGu);
            } else {
                this.$emit('downloadRepPaid');
            }
        },

        adjustDropdownMenuStyle() {
            const dropdownMenu = this.$refs.dropdown.$el.querySelector('.dropdown-menu');
            const dropdownBtn = this.$refs.dropdown.$el.querySelector('button');
            const shift = dropdownMenu.offsetWidth - dropdownBtn.offsetWidth;
            if (dropdownMenu) {
                dropdownMenu.style.transform = `translate3d(-${shift}px, 30px, 0px)`;
            }
        }, // смещение дропдауна скачивания отчетев для форм 02-159-1 и -2
    },

    computed: {
        formLocalText() {
            const localizedText = this.$t("modules.budget_request.calculation_forms.reports.report_headers.common_fields.line_3");
            return this.$i18n.locale === 'kk' ? `${this.form.code} ${localizedText}` : `${localizedText} ${this.form.code}`;
        },
        formLocalPaid() {
            const paid_services = this.$t("modules.budget_request.calculation_forms.table_headers.common.paid_services");
            return `${this.formLocalText} (${paid_services})`;
        },
        isGkkpMode() {
            return this.header && this.header.mode === 'gkkp';
        },
        buttonsText() {
            return this.$t("modules.budget.budget_request");
        },
        batchReportLocalText() {
            if (this.$store.state.isDownloadButtonEnabled) return this.buttonsText.uploadIsProgress;
            if (this.isGkkpMode) return this.buttonsText.batchUploadGkkpButton;
            return this.buttonsText.batchUploadGuButton;
        },
        batchReportGkkpPaid() {
            const paid_services = this.$t("modules.budget_request.calculation_forms.table_headers.common.paid_services");
            return `${this.buttonsText.batchUploadGkkpButton} (${paid_services})`;
        },
        batchReportAbpLocalText() {
            if (this.$store.state.isDownloadButtonEnabled) return this.buttonsText.uploadIsProgress;
            return this.buttonsText.batchUploadAbpButton;
        },
        batchReportEducationDepText() {
            if (this.$store.state.isDownloadButtonEnabled) return this.buttonsText.uploadIsProgress;
            return this.buttonsText.batchUploaEduButton;
        },
        isABPReportAvailable() {
            return this.guListLen > 1 && !this.isGkkpMode && this.isHeadGu;
        }
    }
}
</script>

<style scoped>
.spinner-border-sm {
    width: 1rem !important;
}
</style>