<template>
    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
        <template #button-content>
            <div class="btn-download">
                <div>
                                    <span class="left">
                                        <i class="icon icon-info"></i>
                                    </span>
                    <i class="icon icon-keyboard"></i>
                </div>
            </div>
        </template>
        <b-dropdown-text>
            <div class="info-button">
                <span @click="infoCostForm">{{ getLocText('title') }}<i class="icon icon-folder"></i></span>
            </div>
            <div class="info-button">
                <span class="font-italic" @click="openVideoOnNewTab">{{ getLocText('video') }}<i
                    class="icon icon-video"></i></span>
            </div>
            <div class="info-text">
                <p >{{ getLocText('dscription_1') }} </p>
            </div>
            <div class="info-text">
                <p>{{ getLocText('dscription_2') }} </p>
            </div>
        </b-dropdown-text>
    </b-dropdown>
</template>

<script>
import axios from 'axios';

export default {
    name: 'budget-attach-file',
    props: {
        isArbitraryForm: {
            type: Boolean,
            required: false,
            defaut: false
        },
    },
    data() {
        return {
            isLoad: false
        };
    },
    methods: {
        async infoCostForm() {
            if (this.isLoad) {
                this.makeToast('info', this.getLocText('attention'), this.getLocText('toast_msg'));
            } else {
                this.isLoad = true;
                await axios({
                    method: 'post',
                    url: '/api-py/get-info-zip/1',
                    responseType: 'blob',
                    data: null
                })
                    .then((response) => response.data)
                    .then((data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        const titleOfFile = this.getLocText('file_title');
                        link.setAttribute('download', titleOfFile + '.zip');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    })
                    .catch((error) => {
                        this.makeToast('danger', `${this.getLocText('err_msg')} infoCostForm()`, error.toString());
                    })
                    .finally(() => {
                        this.isLoad = false;
                    });
            }
        },
        openVideoOnNewTab() {
            if (this.isArbitraryForm) {
                window.open('https://youtu.be/r0vhgW3alQE');
            } else {
                window.open('https://youtu.be/r0vhgW3alQE');
            }
        },
        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        getLocText(field, id = -1) {
            const mainTextFieldName = "modules.budget_request.guide." + field;
            if (id >= 0) return this.$tc(mainTextFieldName, id);
            return this.$t(mainTextFieldName);
        }
    }
};
</script>

<style>
.actions-tab-budget-forms .b-dropdown.info .dropdown-menu {
    transform: translate3d(-287px, 30px, 0px) !important;
    text-transform: none;
}

.actions-tab-budget-forms .b-dropdown .dropdown-menu {
    text-transform: none;
}
</style>